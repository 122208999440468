<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { Showtime } from '../hooks/useShowtimes'
import { useDate } from 'vuetify'
import { format } from 'date-fns-tz'
import { formatISO } from 'date-fns'
import ShowtimeTags from './ShowtimeTags.vue'

const { showtimes, date } = defineProps<{
  showtimes: Showtime[]
  date: Date[]
}>()

const emit = defineEmits<{
  (e: 'click', showtime: Showtime): void
}>()

const adapter = useDate()

const currentShowtimesByDate = computed(() => {
  if (!date.length) return []
  return Object.values(
    showtimes
      .filter((showtime) => {
        return adapter.isSameMonth(adapter.date(showtime.date), date[0])
      })
      .reduce(
        (obj, showtime) => {
          const iso = formatISO(showtime.date, { representation: "date" })
          const formatted = format(showtime.date, 'cccc, MMMM do', {
            timeZone: 'Europe/Stockholm'
          })

          if (!obj[iso]) {
            obj[iso] = { date: { iso, formatted }, showtimes: [] }
          }
          obj[iso].showtimes.push(showtime)
          return obj
        },
        {} as Record<string, { date: { iso: string, formatted: string }, showtimes: Showtime[] }>
      )
  )
})

onMounted(() => {
  const isoToday = formatISO(new Date(), { representation: "date" })

  const element = document.getElementById(isoToday);

  if (!element) return;

  window.scrollTo({
    top: element.getBoundingClientRect().top,
    behavior: "smooth"
  })
})

</script>

<template>
  <div class="pa-4 w-100">
    <div v-for="group in currentShowtimesByDate" :key="group.date.iso" class="mb-4" :id="group.date.iso">
      <span class="text-h6 text-grey-darken-1">{{ group.date.formatted }}</span>
      <div>
        <div v-for="showtime in group.showtimes" :key="showtime.url">
          <v-card class="my-3 pa-3 elevation-0 bg-grey-lighten-4" @click="emit('click', showtime)">
            <div class="d-flex justify-space-between">
              <span class="text-subtitle-1 font-weight-bold">
                {{ showtime.movie.title }}
              </span>
              <span class="text-subtitle-1">{{
                format(showtime.date, 'HH:mm', {
                  timeZone: 'Europe/Stockholm'
                })
              }}</span>
            </div>
            <div class="pt-2">
              <ShowtimeTags :showtime="showtime" />
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .tags-container {
    display: grid;
    gap: 4px;
  }
</style>
